import * as React from "react"
import Layout from "../components/layout"
const pageTitle = "Thank You"

export default function ThankYouPage() {
  return (
    <Layout pageTitle={pageTitle}>
      <header className="page-header">
        <h1>{pageTitle}</h1>
      </header>
      <section className="reading container">
        <p>Thank you, we'll get back to you as soon as possible.</p>
      </section>
    </Layout>
  )
}
